import React from "react";
import { useNavItems as themeUseNavItems } from "gatsby-theme-carbon/src/util/NavItems";
import ListBadge from "../../components/ListBadge";

import {
  IconArticle,
  IconBrush,
  IconCodeDots,
  IconInfoSquareRounded,
  IconLayoutCollage,
  IconMoodPlus,
  IconNews,
  IconPalette,
  IconPuzzle,
  IconSmartHome,
  IconStack2,
  IconTemplate,
} from "@tabler/icons-react";

// add nav items
export function useNavItems() {
  const navItems = themeUseNavItems();
  return navItems.concat(
    {
      title: (
        <div className="labelsidebar">
          <IconSmartHome size={20} /> Home
        </div>
      ),
      pages: [{ path: "/", label: "Home" }],
    },
    {
      title: (
        <div className="labelsidebar">
          <IconNews size={20} /> About Legion
        </div>
      ),
      pages: [{ path: "/about-legion", label: "About Legion" }],
    },
    {
      title: (
        <div className="labelsidebar">
          <IconPalette size={20} /> Design
        </div>
      ),
      pages: [
        {
          path: "/design/getting-started/for-telkom-employee",
          title: "Get Started",
          label: "Get Started",
        },
        {
          path: "/design/principles",
          title: "Principles",
          label: "Principles",
        },
        {
          path: "/design/project-setup",
          title: "Project Setup",
          label: "Project Setup",
        },
        {
          path: "/design/guideline/foundation-setup",
          title: "Guideline",
          label: "Guideline",
        },
      ],
    },
    {
      title: (
        <div className="labelsidebar">
          <IconCodeDots size={20} /> Development
        </div>
      ),
      pages: [
        {
          path: "/development/installation/website",
          title: "Installation",
          label: "Installation",
        },
        {
          path: "/development/configuration/website",
          title: "Configuration",
          label: "Configuration",
        },
      ],
    },
    {
      title: (
        <div className="labelsidebar">
          <IconMoodPlus size={20} /> Contribute & Request
        </div>
      ),
      pages: [
        {
          path: "/contribute-and-request",
          label: "Contribute & Request",
        },
      ],
    },
    {
      title: (
        <div className="labelsidebar">
          <IconStack2 size={20} /> Foundation
        </div>
      ),
      pages: [
        { path: "/foundation/overview", title: "Overview", label: "Overview" },
        {
          path: "/foundation/accessibility",
          title: "Accessibility",
          label: "Accessibility",
        },
        {
          path: "/foundation/color-system/guideline",
          title: "Color System",
          label: "Color System",
        },
        {
          path: "/foundation/typography/guideline",
          title: "Typography",
          label: "Typography",
        },
        {
          path: "/foundation/spacer/guideline",
          title: "Spacer",
          label: "Spacer",
        },
        {
          path: "/foundation/radius/website",
          title: "Radius",
          label: "Radius",
        },
        {
          path: "/foundation/elevation/website",
          title: "Elevation",
          label: "Elevation",
        },
      ],
    },
    {
      title: (
        <div className="labelsidebar">
          <IconPuzzle size={20} /> Components
        </div>
      ),
      pages: [
        { path: "/components/overview", title: "Overview", label: "Overview" },
        {
          path: "/components/accordion/guideline",
          title: <ListBadge text="Accordion" type="new" />,
          label: "Accordion",
        },
        {
          path: "/components/alert/guideline",
          title: <ListBadge text="Alert" type="new" />,
          label: "Alert",
        },
        {
          path: "/components/alertdialog/react-native",
          title: <ListBadge text="Alert Dialog" />,
          label: "Alert Dialog",
        },
        {
          path: "/components/anchor/guideline",
          title: <ListBadge text="Anchor" type="new" />,
          label: "Anchor",
        },
        {
          path: "/components/avatar/guideline",
          title: <ListBadge text="Avatar" />,
          label: "Avatar",
        },
        {
          path: "/components/badge/guideline",
          title: <ListBadge text="Badge" />,
          label: "Badge",
        },
        {
          path: "/components/bottombar/android",
          title: <ListBadge text="Bottom Bar" />,
          label: "Bottom Bar",
        },
        {
          path: "/components/bottomsheet/guideline",
          title: <ListBadge text="Bottom Sheet" type="new" />,
          label: "Bottom Sheet",
        },
        {
          path: "/components/box/website",
          title: <ListBadge text="Box" />,
          label: "Box",
        },
        {
          path: "/components/breadcrumb/guideline",
          title: <ListBadge text="Breadcrumb" />,
          label: "Breadcrumb",
        },
        {
          path: "/components/button/guideline",
          title: <ListBadge text="Button" />,
          label: "Button",
        },
        {
          path: "/components/card/guideline",
          title: <ListBadge text="Card" />,
          label: "Card",
        },
        {
          path: "",
          title: <ListBadge text="Carousel" type="upcoming" />,
          label: "carousel",
        },
        {
          path: "/components/checkbox/guideline",
          title: <ListBadge text="Checkbox" />,
          label: "Checkbox",
        },
        {
          path: "/components/chips/guideline",
          title: <ListBadge text="Chips" />,
          label: "Chips",
        },
        {
          path: "/components/container/website",
          title: <ListBadge text="Container" />,
          label: "Container",
        },
        {
          path: "/components/datepicker/guideline",
          title: <ListBadge text="Date Picker" />,
          label: "Date Picker",
        },
        {
          path: "/components/divider/guideline",
          title: <ListBadge text="Divider" type="new" />,
          label: "Divider",
        },
        {
          path: "/components/dropdown/guideline",
          title: <ListBadge text="Dropdown" />,
          label: "Dropdown",
        },
        {
          path: "/components/fileupload/guideline",
          title: <ListBadge text="File Upload" />,
          label: "File Upload",
        },
        {
          path: "/components/flex/website",
          title: <ListBadge text="Flex" />,
          label: "Flex",
        },
        {
          path: "/components/image/guideline",
          title: <ListBadge text="Image" />,
          label: "Image",
        },
        {
          path: "/components/imageUpload/android",
          title: <ListBadge text="Image Upload" />,
          label: "Image Upload",
        },
        {
          path: "/components/list/website",
          title: <ListBadge text="List" />,
          label: "List",
        },
        {
          path: "/components/modal/guideline",
          title: <ListBadge text="Modal" />,
          label: "Modal",
        },
        {
          path: "/components/navigationbar/guideline",
          title: <ListBadge text="Navigation Bar" />,
          label: "Navigation Bar",
        },
        {
          path: "/components/pagination/guideline",
          title: <ListBadge text="Pagination" />,
          label: "Pagination",
        },
        {
          path: "/components/pininput/guideline",
          title: <ListBadge text="Pin Input" />,
          label: "Pin Input",
        },
        {
          path: "/components/progressbar/guideline",
          title: <ListBadge text="Progress Bar" />,
          label: "Progress Bar",
        },
        {
          path: "/components/progresscircle/guideline",
          title: <ListBadge text="Progress Circle" />,
          label: "Progress Circle",
        },
        {
          path: "/components/radiobutton/guideline",
          title: <ListBadge text="Radio Button" type="new" />,
          label: "Radio Button",
        },
        {
          path: "/components/rating/guideline",
          title: <ListBadge text="Rating" />,
          label: "Rating",
        },
        {
          path: "/components/selectfield/guideline",
          title: <ListBadge text="Select Field" />,
          label: "Select Field",
        },
        {
          path: "/components/sidebar/guideline",
          title: <ListBadge text="Sidebar" />,
          label: "Sidebar",
        },
        {
          path: "/components/slider/guideline",
          title: <ListBadge text="Slider" />,
          label: "Slider",
        },
        {
          path: "/components/snackbar/guideline",
          title: <ListBadge text="Snackbar" type="new" />,
          label: "Snack Bar",
        },
        {
          path: "/components/spinner/guideline",
          title: <ListBadge text="Spinner" />,
          label: "Spinner",
        },
        {
          path: "/components/stepper/guideline",
          title: <ListBadge text="Stepper" />,
          label: "Stepper",
        },
        {
          path: "/components/switch/guideline",
          title: <ListBadge text="Switch" type="new" />,
          label: "Switch",
        },
        {
          path: "/components/tabBar/guideline",
          title: <ListBadge text="Tab Bar" />,
          label: "Tab Bar",
        },
        {
          path: "/components/table/guideline",
          title: <ListBadge text="Table" />,
          label: "Table",
        },
        {
          path: "/components/textarea/guideline",
          title: <ListBadge text="Textarea" />,
          label: "TextArea",
        },
        {
          path: "/components/textfield/guideline",
          title: <ListBadge text="Textfield" />,
          label: "TextField",
        },
        {
          path: "/components/timepicker/guideline",
          title: <ListBadge text="Time Picker" />,
          label: "Time Picker",
        },
        {
          path: "/components/tooltip/guideline",
          title: <ListBadge text="Tooltip" type="new" />,
          label: "Tooltip",
        },
        {
          path: "/components/tree/guideline",
          title: <ListBadge text="Tree" />,
          label: "Tree",
        },
        {
          path: "/components/viewstate/android",
          title: <ListBadge text="View State" />,
          label: "View State",
        },
      ],
    },
    {
      title: (
        <div className="labelsidebar">
          <IconLayoutCollage size={20} /> Pattern
        </div>
      ),
      pages: [
        { path: "/pattern/overview", title: "Overview", label: "Overview" },
        {
          path: "/pattern/dashboard/guideline",
          title: <ListBadge text="Dashboard" />,
          label: "Dashboard",
        },
        {
          path: "/pattern/login-and-register/guideline",
          title: <ListBadge text="Login & Register" />,
          label: "Login & Register",
        },
        {
          path: "/pattern/onboarding/mobile",
          title: "On Boarding",
          label: "On Boarding",
        },
        {
          path: "/pattern/user-management/guideline",
          title: <ListBadge text="User Management" />,
          label: "User Management",
        },
      ],
    },
    {
      title: (
        <div className="labelsidebar">
          <IconArticle size={20} /> Content
        </div>
      ),
      pages: [
        { path: "/content/overview", title: "Overview", label: "Overview" },
        {
          path: "/content/writing-principles/website",
          title: "Writing Principles",
          label: "Writing Principles",
        },
        {
          path: "/content/mechanics/website",
          title: "Mechanics",
          label: "Mechanics",
        },
        {
          path: "/content/grammar/website",
          title: "Grammar",
          label: "Grammar",
        },
        {
          path: "/cookie-policy",
          title: "Cookie Policy",
          label: "Cookie Policy",
        },
      ],
    },
    {
      title: (
        <div className="labelsidebar">
          <IconBrush size={20} /> Illustrations
        </div>
      ),
      pages: [
        {
          path: "/illustrations/overview",
          label: "Overview",
          title: "Overview",
        },
        {
          path: "/illustrations/pijar-belajar",
          label: "Pijar Belajar",
          title: "Pijar Belajar",
        },
        {
          path: "/illustrations/antares-eazy",
          label: "Antares Eazy",
          title: "Antares Eazy",
        },
        {
          path: "/illustrations/mytens",
          label: "MyTEnS",
          title: "MyTEnS",
        },
        {
          path: "/illustrations/guideline/principles",
          label: "Guideline",
          title: "Guideline",
        },
      ],
    },
    {
      title: (
        <div className="labelsidebar">
          <IconInfoSquareRounded size={20} /> Release Notes
        </div>
      ),
      pages: [{ path: "/release-notes/website", label: "Release Notes" }],
    },
    {
      title: (
        <div className="labelsidebar">
          <IconTemplate size={20} /> Theme Generator
        </div>
      ),
      pages: [{ path: "/theme-generator", label: "Theme Generator" }],
    },
  );
}